import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let rafIntroHover = null,
    cursorPosition = [],
    offset = [],
    oldPosition = [],
    thumbPos = [];

window.addEventListener('mousemove', (e) => cursorPosition = [e.pageX, e.pageY - window.scrollY]);
window.addEventListener("DOMContentLoaded", () => init());

function init() {

  TouchDevice.init();

  stickyHeader();
  window.addEventListener('scroll', (e) => stickyHeader());

  document.querySelectorAll('.dezoom').forEach(el => {
    gsap.to(el.querySelector('img'), {
      scale:1,
      duration:2,
      ease:"power4.out",
      scrollTrigger: {
        trigger:el,
        start:'top bottom'
      }
    })
  })

  if(document.querySelector('h1 span')){
    gsap.to('h1 span', {
      y:'0%',
      ease:"power3.out",
      duration:1,
      delay:0.5
    })
  }
  
  if(document.body.classList.contains('home')){
    popup();
    if(!TouchDevice.isTouchDevice()) hoverTextImg();

    gsap.fromTo('.img1', {
      y:'40%'
    },{
        scrollTrigger: {
            trigger:'.carte',
            start:'top bottom',
            end:'bottom top',
            scrub:true
        },
        y:'-40%',
        ease:'none',
        immediateRender:false
    })
    gsap.fromTo('.img2', {
      y:'40%'
    },{
        scrollTrigger: {
            trigger:'.carte',
            start:'top bottom',
            end:'bottom top',
            scrub:true
        },
        y:'-10%',
        ease:'none',
        immediateRender:false
    })

    gsap.fromTo('.img5', {
      y:'40%'
    },{
        scrollTrigger: {
            trigger:'.img5',
            start:'top bottom',
            end:'bottom top',
            scrub:true
        },
        y:'-20%',
        ease:'none',
        immediateRender:false
    })
  }else if(document.querySelector('.page-template-page-carte')){

    gsap.fromTo('.imgRotate', {
      rotation:10,
      y:'50%'
    },{
        scrollTrigger: {
            trigger:'.lesPlats',
            start:'top bottom',
        },
        rotation:-5,
        duration:2,
        y:'0%',
        ease:'power4.out',
        immediateRender:false
    })

  }else if(document.querySelector('.page-template-page-privatisation')){
    gsap.fromTo('.imgD1', {
      y:'20%'
    },{
        scrollTrigger: {
            trigger:'.equipes',
            start:'top bottom',
            end:'bottom top',
            scrub:true
        },
        y:'-20%',
        ease:'none',
        immediateRender:false
    })

    gsap.fromTo('.imgD2', {
      y:'40%'
    },{
        scrollTrigger: {
            trigger:'.equipes',
            start:'top bottom',
            end:'bottom top',
            scrub:true
        },
        y:'-40%',
        ease:'none',
        immediateRender:false
    })

    gsap.fromTo('.imgCocktail2', {
      y:'40%'
    },{
        scrollTrigger: {
            trigger:'.cocktail',
            start:'top bottom',
            end:'bottom top',
            scrub:true
        },
        y:'-40%',
        ease:'none',
        immediateRender:false
    })

    gsap.fromTo('.full img', {
      y:'-200px'
    },{
        scrollTrigger: {
            trigger:'.full',
            start:'top bottom',
            end:'bottom top',
            scrub:true,
        },
        y:"0px",
        ease:'none',
        immediateRender:false
    })
  }

  document.addEventListener('click', function (event) {
    if (event.target.closest('.closeContact') || event.target.closest('.closeContact2')) {
      gsap.to('#contact', {opacity:0, duration:0.5, ease:"power2.inOut", onComplete:() => {
        document.body.classList.remove('bloque')
        document.getElementById('contact').classList.add('off')
      }})
    }else if (event.target.closest('.toContact')) {
      document.body.classList.add('bloque')
      document.getElementById('contact').classList.remove('off')
      gsap.fromTo('#contact', {opacity:0}, {opacity:1, duration:0.5, ease:"power2.inOut"})
      gsap.fromTo('#contact h2', {y:'-50%', opacity:0}, {y:'0%', opacity:1, duration:1, ease:"power2.out"})
      gsap.fromTo('#contact h2 svg', {y:'-50%', opacity:0}, {y:'0%', opacity:1, duration:1.5, ease:"power2.out"})
    }else if (event.target.closest('.toTop')){
      let progress = {y:window.scrollY};
      gsap.to(progress, {
        y: 0,
        duration:1.2,
        ease:"power3.inOut",
        onUpdate:() => {
          window.scrollTo(0, progress.y)
        }
      })
    }else if (event.target.closest('.toMenu')){
      document.querySelector('.innerMenu').classList.add('actif');
    }else if (event.target.closest('.closeMenu')){
      document.querySelector('.innerMenu').classList.remove('actif');
    }
  });
}

/* Utils */
function indexInParent(node) {
  var children = node.parentNode.childNodes;
  var num = 0;
  for (var i=0; i<children.length; i++) {
    if (children[i]==node) {return num;}
    if (children[i].nodeType==1) {num++;}
  }
  return -1;
}


/* Popup page d'accueil */
function popup() {
  if (!document.body.classList.contains('page-template-page-accueil') || !document.querySelector('.popup')) return;
  document.querySelector('.popup-close').addEventListener('click', () => closePopup());
  sessionStorage.getItem('popupStade') == null ? showPopup() : removePopup();
}
function showPopup(){
  gsap.to('.popup', {opacity: 1, duration: 0.2, delay: 1})
  document.querySelector('.popup').classList.add('is-visible');
}
function closePopup() {
  sessionStorage.setItem('popupStade', 'ok');
  gsap.to('.popup', {opacity: 0, duration: 0.2, onComplete:()=>removePopup()})
}
function removePopup() {
  document.querySelector('.popup-container').parentNode.removeChild(document.querySelector('.popup-container'));
}

/* Interaction texte d'intro (accueil) */
function hoverTextImg() {
  document.querySelector('.situe p').addEventListener('mouseenter', () => followPicture());
  document.querySelector('.situe p').addEventListener('mouseleave', () => {
    cancelAnimationFrame(rafIntroHover); rafIntroHover=null;
  });
  Array.prototype.forEach.call(document.querySelectorAll('.situe p span'), (string) => {
    string.addEventListener('mouseenter', () => setPicture(indexInParent(string)));
    string.addEventListener('mouseleave', () => hidePicture());
  });
}
function setPicture(index) {
  document.querySelector('.situe-photos').style = "background-image: url("+document.querySelector('.situe-photos').getAttribute('data-photo-'+index)+")";
  showPicture();
}
function showPicture(){
  document.querySelector('.situe-photos').classList.add('is-visible');
}
function hidePicture(){
  document.querySelector('.situe-photos').classList.remove('is-visible');
}
function followPicture() {
  rafIntroHover = requestAnimationFrame(followPicture);
  if (document.querySelector('.situe p span')!=null) {
    offset[0] = cursorPosition[0] - oldPosition[0];
    oldPosition[0] = cursorPosition[0];
    offset[1] = cursorPosition[1] - oldPosition[1];
    oldPosition[1] = cursorPosition[1];
    thumbPos[0] = cursorPosition[0] - document.querySelector('.situe').getBoundingClientRect().left;
    thumbPos[1] = cursorPosition[1] - document.querySelector('.situe').getBoundingClientRect().top;
    gsap.to('.situe-photos', {duration:0.8, x:thumbPos[0]+'px', y:thumbPos[1]+'px', ease:'power4.out'});
  }
}

function stickyHeader() {
  if (window.scrollY > 0 && !document.querySelector('header').classList.contains('not-on-top')) document.querySelector('header').classList.add('not-on-top');
  if (window.scrollY == 0 && document.querySelector('header').classList.contains('not-on-top')) document.querySelector('header').classList.remove('not-on-top');
}

export default class TouchDevice {
  static init() {
    if (this.isTouchDevice()) {
      document.body.classList.add('touch-device');
    }
  }

  static isTouchDevice() {
    if (this.isTouch) {
      return this.isTouch;
    }

    if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof window.DocumentTouch)) {
      this.isTouch = true;
      return this.isTouch;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = query => window.matchMedia(query).matches;
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    this.isTouch = mq(query);
    return this.isTouch;
  }
}

